<style lang="scss"></style>
<template>
  <div class="promotion-container">
    <!-- <div class="hidden-sm-and-down">
        <v-carousel class="banner" cycle delimiter-icon="panorama_fish_eye" :show-arrows="false" interval="10000" height="auto">
            <v-carousel-item v-for="(banner, i) in bannerContent" :key="i">
                <v-img :src="`${banner.desktop_banner}`" @click="callBannerAction(banner.action)" :contain="true" :aspect-ratio="3"></v-img>
            </v-carousel-item>
        </v-carousel>
    </div> -->
    <v-img class="hidden-sm-and-down white--text align-end pa-0"
           :aspect-ratio="$vuetify.breakpoint.smAndDown ? 360 / 88 : 1800 / 200"
           :src="`${mediaUrl}/game_banner/promotion.jpg`">
      <v-card-title class="promotion-banner-text font-weight-bold">
        <p class="ml-3">
                      <span class="text-capitalize">
                          {{ $t(`label.promotion`) }}
                      </span>
        </p>
      </v-card-title>
    </v-img>
    <div class="bonus-category-container">
      <div class="bonus-category-wrapper mx-3">
        <v-btn @click="getCmsBonus(0)" class="bonus-button"
               :class="searchCriteria.bonusCategoryId == 0 ? 'bonus-button-active' : ''" height="auto" text
               :ripple="false" depressed>
          <label class="bonus-category-menu-title">{{ $t(`label.all`) }}</label>
        </v-btn>
        <v-btn v-for="bonusCategory in translatedBonusCategories" :key="bonusCategory.id"
               @click="getCmsBonus(bonusCategory.id)" class="bonus-button"
               :class="searchCriteria.bonusCategoryId == bonusCategory.id ? 'bonus-button-active' : ''" height="auto"
               text :ripple="false" depressed>
          <label class="bonus-category-menu-title">{{ bonusCategory.name }}</label>
        </v-btn>
      </div>
    </div>
    <v-row class="promotion-wrapper my-5">
      <v-col v-for="(bonus, index) in bonusContent" :key="index" no-gutters cols="12" md="6" lg="6">
        <v-card v-if="timeDiff(bonus.bonus_details.end_time)" class="promotion-card">
          <v-img :src="`${bonus.mobile_img}`" :aspect-ratio="588 / 218"></v-img>

          <v-row class="promotion-desc-container" no-gutters>
            <div class="promotion-desc-deco hidden-sm-and-down"></div>
            <v-col cols="12" class="promotion-desc-title-container">
              <div class="mb-1 font-weight-bold">{{ bonus.title }}</div>
              <div class="promotion-end-date hidden-sm-and-down">{{
                  timeDiff(bonus.bonus_details.end_time) ? stringFormat($t(`label.endIn`), timeDiff(bonus.bonus_details.end_time)) : $t(`label.promotionEnd`)
                }}
              </div>
            </v-col>
            <v-col cols="12" class="promotion-desc-box">
              <div class="mb-1 d-block text-truncate">{{ bonus.desc }}</div>
              <div class="promotion-desc-time" v-if="$vuetify.breakpoint.lgAndUp">
                <v-icon size="22" class="promotion-time-icon">access_time</v-icon>
                <div>{{ bonus.bonus_details.created_at }} to {{ bonus.bonus_details.end_time }}</div>
              </div>
            </v-col>

            <v-col class="promotion-button-container">
              <button-primary :action="() => onApplyBonusClick(bonus)" :title="$t(`label.applyNow`)"
                              class="promotion-button caption font-weight-bold" v-if="bonus.has_apply_button"></button-primary>
              <button-primary :action="() => openPromotionTnc(bonus)" :title="$t(`button.details`)"
                              class="promotion-button caption font-weight-bold"></button-primary>
              <span class="caption d-flex align-center justify-center" v-if="$vuetify.breakpoint.mdAndDown">
                <v-icon size="14" class="promotion-time-icon">access_time</v-icon>{{
                  timeDiff(bonus.bonus_details.end_time) ? stringFormat($t(`label.endIn`), timeDiff(bonus.bonus_details.end_time)) : $t(`label.promotionEnd`)
                }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="text-center" v-if="bonusContent.length <= 0" cols="12">
        <p class="text-center text-capitalize mb-0">{{ $t(`label.noPromotionAvaliable`) }}</p>
      </v-col>
    </v-row>

    <app-dialog :dialogShow="promotionDialog.show" :max-width="1100" :title="promotionDialog.title"
                :closeAction="promotionDialog.dialogXButton" :scrollable="true">
      <v-container
          :class="[$vuetify.breakpoint.mdAndDown ? 'px-3' : 'px-5', $vuetify.breakpoint.smAndDown ? 'maxFrameHeight' : 'fill-height']">
        <div class="cms_bonus_content text-left" style="width: 100%">
          <v-row no-gutters>
            <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
              <v-btn v-if="isAllowApply" class="mb-4 full-width font-weight-bold pa-2 buttonPrimary primary-button"
                     style="width: 100%" @click="onApplyBonusClick(selectedBonusObj)">
                {{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="10" lg="10" sm="10">
              <p class="font-weight-bold mb-0">{{ $t(`label.promotionPeriod`) }}</p>
              <p>{{ promoData.start_time | utcToLocalTime }} - {{ promoData.end_time | utcToLocalTime }}</p>
            </v-col>
            <v-col cols="2" v-if="$vuetify.breakpoint.smAndUp && selectedBonusObj && selectedBonusObj.has_apply_button">
              <v-btn class="full-width font-weight-bold pa-2 buttonPrimary primary-button"
                     @click="onApplyBonusClick(selectedBonusObj)">{{ $t(`label.applyNow`) }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" v-html="promoHtml" :class="[this.language, 'cms_bonus_content text-left']"></v-col>
          </v-row>
        </div>
      </v-container>
    </app-dialog>
  </div>
</template>

<script>
import format from 'string-format'
import {
  DevicePlatform
} from '@/constants/enums'
import {
  uiHelper,
  locale
} from '@/util'
import {
  ROUTE_NAME
} from '@/constants/route.constants'
import {
  BONUS_ANON_LIST,
  BONUS_LIST,
  BONUS_MANUAL_BONUS_LIST,
  BONUS_APPLY_BONUS
} from '@/store/bonus.module'
import {
  CACHE_KEY
} from '@/constants/constants'
import axios from 'axios'
import NavFilter from '@/assets/svg/navfilter.svg'
import {
  SHARED
} from '../../constants/constants'
import {
  CMS_BONUS_ACTIVE
} from '@/store/cms.module'
import {
  BONUS_RESET_APPLY_BONUS_RESPONSE_STORE
} from '../../store/bonus.module'
import {
  errorCodeHelper
} from '../../util'
import {FOOTER_SEO} from "@/store/lookup.module";

export default {
  name: 'promotion',
  props: {
    selectedType: {
      type: String,
      default: '',
    },
    cmsBonusCategoryId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    NavFilter,
  },
  data: () => ({
    stringFormat: format,
    promoHtml: '',
    promoData: {},
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    promotionFilterSheet: false,
    isAllowApply: false,
    currentLanguage: uiHelper.getLanguage(),
    selectedBonusId: '',
    selectedBonusObj: null,
    promotionFound: false,
    promotionTncUrl: '',
    promotionDialog: {
      show: false,
      title: locale.getMessage(`label.promotion`),
      dialogXButton: function () {
      },
    },
    selectedGameName: '',
    selectedCategory: 'all',
    gameProvider: '',
    gameName: '',
    promoType: '',
    filteredSlotGame: [],
    searchCriteria: {
      bonusCategoryId: 0,
      pageNumber: 1,
      pageSize: 20,
    },
    manualPromotionList: [],
    promotionList: [],
    language: uiHelper.getLanguage()
  }),
  computed: {
    translatedBonusCategories() {
      let categories = this.$store.state.cms.cmsBonusCategories
      return categories.map((c) => {
        const localizedText = c.localization.filter((l) => l.hasOwnProperty(this.currentLanguage))[0]
        if (localizedText) {
          return {
            name: localizedText[this.currentLanguage],
            id: c.id,
          }
        }
        return {
          name: c.name,
          id: c.id,
        }
      })
    },
    bonusApplyResult() {
      return this.$store.state.bonus.applyBonusResponse.complete
    },
    cmsBonusCategories() {
      return this.$store.state.cms.cmsBonusCategories
    },
    bonusContent() {
      return this.$store.state.cms.bonusContent
    },
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    bonusAnonList() {
      return this.$store.state.bonus.bonusAnonList
    },
    bonusList() {
      return this.$store.state.bonus.bonusList
    },
    manualBonusList() {
      return this.$store.state.bonus.manualBonusList
    },
  },
  watch: {
    async isLoggedIn() {
      if (this.isLoggedIn) {
        await this.getAvailableBonus()
      } else {
        await this.getAnonBonus()
      }
      this.populateDisplayBonusList()
    },
    selectedType() {
      this.searchCriteria.gameType = this.selectedType
    },
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes
    },
    async bonusAnonList() {
      this.populateDisplayBonusList()
      return this.$store.state.bonus.bonusAnonList
    },
    async bonusList() {
      this.populateDisplayBonusList()
      return this.$store.state.bonus.bonusList
    },
    manualBonusList() {
      return this.$store.state.bonus.manualBonusList
    },
    bonusApplyResult() {
      if (this.$store.state.bonus.applyBonusResponse.complete) {
        this.closePromotionTncDialog()
        if (this.$store.state.bonus.applyBonusResponse.success) {
          this.$parent.openAlert(true, locale.getMessage(`message.bonusApplied`))
        } else {
          if (this.$store.state.bonus.applyBonusResponse.code == '5.16') {
            this.$parent.openAlert(true, locale.getMessage(`message.bonusApplied`))
          } else {
            this.$parent.openAlert(false, locale.getMessage(`message.promotionBonusFailed`))
          }
        }
        this.selectedBonusObj = null
        this.$store.dispatch(`${BONUS_RESET_APPLY_BONUS_RESPONSE_STORE}`)
        this.getCmsBonus(this.searchCriteria.bonusCategoryId)
      }
    },
  },
  async created() {
    this.getPreopenCondition()
    this.getCmsBonus(0)
    // this.setFooterSEO()
  },
  methods: {
    setFooterSEO() {
      let pageObj = {
        currentRoute : this.$route.name
      }
      this.$store.dispatch(`${FOOTER_SEO}`, {pageObj})
    },
    timeDiff(datetime) {
      var datetime = typeof datetime !== 'undefined' ? datetime : new Date().getTime()
      const datetimeArr = datetime.split(' ')
      const date = datetimeArr[0].split('-')
      const time = datetimeArr[1].split(':')
      var datetime = new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2])
      var now = new Date()
      if (isNaN(datetime)) {
        return false
      }
      var milisec_diff = datetime - now;

      if (milisec_diff < 0) {
        return false
      }

      var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24))
      var date_diff = new Date(milisec_diff)
      var remainingHours = Math.floor((milisec_diff / 1000 / 60 / (60 * 24) - days) * 24)
      // return days + " Days " + date_diff.getHours() + " Hours " + date_diff.getMinutes() + " Minutes " + date_diff.getSeconds() + " Seconds";
      return days + ' D ' + remainingHours + ' H '
    },
    populateDisplayBonusList() {
      this.promotionList = []
      if (this.isLoggedIn) {
        this.bonusList.forEach((bonus) => {
          this.promotionList.push(bonus)
        })

        this.promotionList.sort(function (x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function (x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })

        this.manualBonusList.forEach((bonus) => {
          this.promotionList.push(bonus)
        })
        this.promotionList.sort(function (x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function (x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })
      } else {
        this.bonusAnonList.forEach((bonus) => {
          this.promotionList.push(bonus)
        })

        this.promotionList.sort(function (x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function (x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })

        this.manualBonusList.forEach((bonus) => {
          this.promotionList.push(bonus)
        })
        this.promotionList.sort(function (x, y) {
          if (y.endDate > x.endDate) return -1
          if (x.endDate < y.endDate) return 1
        })

        this.promotionList.sort(function (x, y) {
          if (x.isPin > y.isPin) {
            return -1
          } else if (x.isPin == y.isPin) {
            return x.isPin
          } else {
            return 1
          }
        })
      }
    },
    getPreopenCondition() {
      let bonusId = this.$route.params.bonusId

      if (bonusId != null && bonusId != undefined) {
        this.openPromotionTnc(bonusId)
      }
    },
    selectFilterType(filterType) {
      this.$emit('updateFilterType', filterType)
      this.promotionFilterSheet = false
    },
    async getManualPromotion() {
      await this.$store.dispatch(`${BONUS_MANUAL_BONUS_LIST}`)
    },
    getBonusTitle(bonusLocale) {
      if (bonusLocale.find((x) => x.language == this.currentLanguage) != null && bonusLocale.find((x) => x.language == this.currentLanguage) != undefined) {
        return bonusLocale.find((x) => x.language == this.currentLanguage).title
      } else if (bonusLocale.find((x) => x.language == 'en') != null && bonusLocale.find((x) => x.language == 'en') != undefined) {
        return bonusLocale.find((x) => x.language == 'en').title
      } else {
        return bonusLocale[0].title
      }
    },
    getBonusDesc(bonusLocale) {
      if (bonusLocale.find((x) => x.language == this.currentLanguage) != null && bonusLocale.find((x) => x.language == this.currentLanguage) != undefined) {
        return bonusLocale.find((x) => x.language == this.currentLanguage).desc
      } else if (bonusLocale.find((x) => x.language == 'en') != null && bonusLocale.find((x) => x.language == 'en') != undefined) {
        return bonusLocale.find((x) => x.language == 'en').desc
      } else {
        return bonusLocale[0].desc
      }
    },
    async getPromotionBonus() {
      if (this.isLoggedIn) {
        await this.getAvailableBonus()
      } else {
        await this.getAnonBonus()
      }
      await this.getManualPromotion()
      this.populateDisplayBonusList()
    },
    getCmsBonus(categoryId) {
      this.searchCriteria.bonusCategoryId = categoryId
      let obj = {
        language: uiHelper.getLanguage(),
        cmsBonusCategoryId: categoryId,
        currency: uiHelper.getCurrency(),
      }
      this.$store.dispatch(`${CMS_BONUS_ACTIVE}`, {
        obj,
      })
      let type = 'cricket'
      switch (this.searchCriteria.bonusCategoryId) {
        case 8:
          type = 'sb'
          break
        case 9:
          type = 'ld'
          break
        case 10:
          type = 'rng'
          break
        case 11:
          type = 'table'
          break
        case 12:
          type = 'lottery'
          break
        case 13:
          type = 'vip'
          break
        default:
          break
      }
      this.promoType = type
    },
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getAnonBonus() {
      this.$store.dispatch(`${BONUS_ANON_LIST}`)
    },
    getPromotionTncFile(id, content) {
      try {
        this.$ga.event('Bonus', `View Bonus`, `Bonus ID - ${id}`)
      } catch (err) {
        console.log(err)
      }
      let timestamp = new Date().getTime()
      let fileUrl = `${`${this.mediaUrl}/html/promotion/${this.currentLanguage}/${id}.html?v=${timestamp}`}`
      let promotionNotFoundUrl = `${process.env.BASE_URL}${`static/html/promotion/${this.currentLanguage}/non-found.html`}`
      this.promotionDialog.dialogXButton = this.closePromotionTncDialog

      let language = uiHelper.getLanguage()
      axios({
        method: 'GET',
        url: content,
        responseType: 'json',
      })
          .then((response) => {
            if (response.status == 200) {
              this.promoHtml = response.data['tnc'].find((x) => x.language == language)['content'].replace(/&nbsp;/g, ' ');

              this.promoData = {
                start_time: response.data.start_time,
                end_time: response.data.end_time,
              }
              this.promotionDialog.show = true
            }
          })
          .catch(() => {
            this.promotionTncUrl = promotionNotFoundUrl
            this.promotionDialog.show = true
            console.log(`Promotion ID : ${id} `)
          })
    },
    onApplyBonusClick(bonus) {
      if (!this.isLoggedIn) {
        this.promptLogin()
        return
      }
      this.applyBonus(bonus)
    },
    applyBonus(bonus) {
      this.selectedBonusObj = bonus
      if (this.selectedBonusObj.redirect_to_deposit) {
        this.applyInDepositPage()
        return
      } else {
        this.onConfirmApplyOk()
        return
      }
      //this.openPromotionTnc(bonus)
    },
    applyInDepositPage() {
      let selectedBonusId = this.selectedBonusObj.bonus_id
      if (uiHelper.getPlatform() == DevicePlatform.MOBILE) {
        let routeData = this.$router.resolve({
          name: ROUTE_NAME.FUNDS,
          params: {
            bonusId: selectedBonusId,
          },
        })
        window.open(routeData.href)
      } else {
        this.$router.push({
          name: ROUTE_NAME.FUNDS,
          params: {
            bonusId: selectedBonusId,
          },
        })
      }
    },
    promptLogin() {
      this.$parent.openLoginDialog()
    },
    openPromotionTnc(bonus) {
      if (bonus != null) {
        this.selectedBonusObj = bonus
        this.isAllowApply = bonus != null
        this.getPromotionTncFile(bonus.id, bonus.content)
      }
    },
    closePromotionTncDialog() {
      this.promotionTncUrl = ''
      this.promotionDialog.show = false
      this.selectedBonusObj = null
    },
    onConfirmApplyOk() {
      const applyBonusObj = {
        bonusId: this.selectedBonusObj.bonus_id,
      }
      this.$store.dispatch(`${BONUS_APPLY_BONUS}`, {
        applyBonusObj,
      })
    },
    confirmationDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.Confirmation`)
      dialog.message.push(locale.getMessage(`label.confirmApplyPromotion`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.onConfirmApplyOk(),
      })
      dialog.button.push({
        title: locale.getMessage(`button.cancel`),
        action: () => this.closePageDialog(),
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: [],
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
  },
}
</script>

<style lang="scss">
.promotion-container {
  background-color: white;

  .promotion-wrapper {
    width: 100%;
    max-width: 1240px;
    margin: auto;

    .promotion-card {
      background-color: #d9d9d978;
      box-shadow: 0px -2px 6px black;

      .promotion-desc-container {
        position: relative;

        p {
          margin: 0;
        }

        .promotion-desc-deco {
          position: absolute;
          z-index: 1;
          top: -0.1875rem;
          left: 0px;
          width: 100%;
          height: 0.4375rem;
          background: url('/static/image/promotion/line.png') center center repeat-x;
        }

        .promotion-desc-title-container {
          position: relative;
          padding: 6px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .promotion-end-date {
            font-size: 12px;
            color: white;
            background-color: var(--v-primary-base);
            padding: 4px 8px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 2px;
            height: 100%;
            background-color: var(--v-secondary-base);
            box-shadow: rgba(15, 25, 46, 0.5) 0px 2px 4px 0px;
          }
        }

        .promotion-desc-box {
          color: #333333;
          padding: 0px 12px;

          .promotion-desc-time {
            position: relative;
            display: flex;
            justify-content: start;
            align-items: center;
            font-size: 12px;
            gap: 4px;

            .promotion-time-icon {
              color: #333333;
            }
          }
        }

        .promotion-button-container {
          display: flex;
          justify-content: start;
          gap: 20px;
          margin: 12px;

          .promotion-button {
            padding: 4px 6px;
            box-shadow: unset;
          }
        }
      }
    }
  }
}

.bonus-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.bonus-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.bonus-category-container {
  //background-color: #474747;
  background-color: #b9b9b98a;

  .bonus-category-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 0px;
    max-width: 1240px;
    white-space: nowrap;
    margin: auto;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;

    .bonus-button {
      cursor: pointer;
      padding: 4px 8px;

      &:hover {
        border: 1px solid var(--v-secondary-base);
        border-radius: 5px;
      }
    }

    .bonus-button-active {
      border: 1px solid var(--v-secondary-base);
      border-radius: 5px;
    }
  }

  .bonus-category-menu-title {
    //color: #ffffff;
    font-size: 16px;
    text-transform: capitalize;
  }
}

@media (max-width: 599px) {
  .bonus-category-container {
    //background-color: #555555;
    background-color: var(--v-background-darken1);

    .bonus-category-wrapper {
      justify-content: start !important;

      .bonus-button {
        //background-color: #333333;
        background-color: var(--v-background-darken2);

        &:hover {
          border: 1px solid var(--v-primary-base);
        }
      }

      .bonus-button-active {
        border: 1px solid var(--v-primary-base) !important;
        background-color: var(--v-primary-base);
      }
    }
  }

  .promotion-container {
    background-color: white;

    .promotion-desc-container {
      background-color: #ffffff;

      .promotion-desc-title-container {
        color: #000000;
      }

      .promotion-time-icon {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.cms_bonus_content {
  font-family: 'ReadexPro-Medium';
  text-align: left;
  padding-bottom: 24px;
  height: 50vh;
  overflow: visible;
}


.cms_bonus_content table {
  width: 100%;
  text-align: center;
  border-collapse: collapse
}

.cms_bonus_content {
  max-height: 450px
}

.cms_bonus_content table,.cms_bonus_content td,.cms_bonus_content th {
  border: 1px solid #000
}

.cms_bonus_content table thead th {
  background-color: #000;
  font-size: 16px;
  color: #fff
}

.cms_bonus_content table tbody tr th {
  font-size: 16px
}

.cms_bonus_content table td {
  padding: 5px
}

.bd p span {
  background-color: black !important;
}

.en p span {
  background-color: white !important;
}

.maxFrameHeight {
  max-height: 100%;
}
</style>
